import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InputMask from "react-input-mask";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {ptBR} from 'date-fns/locale'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  Label,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Typography,
  Button,
  CircularProgress,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  CardContent,
  Card,
  TextField,
  InputLabel,
  Input,
  Checkbox,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  FormLabel,
  FormGroup,
  Modal,
  TableBody,
  Table,
  TableContainer,
  Paper as MuiPaper,
  TableCell,
  TableRow,
  RadioGroup,
  Radio,
  TextareaAutosize
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, fontSize, height, spacing } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";

import { useNavigate } from "react-router-dom";
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

Date.prototype.subtractDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
}

const Paper = styled(MuiPaper)(spacing);

function ChangeSituation(props) {
  
  
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const FontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

  const navigate = useNavigate();

  
  let [signatureId, setSignatureId] = useState('');
  let [cancellation_date, setCancellationDate] = useState('');
  let [suspension_date, setSuspensionDate] = useState('');
  let [action, setAction] = useState(2);
  let [statusNotes, setStatusNotes] = useState(null);
  let [cancel_pending_invoices, setCancelPendingInvoices] = useState(true);
  let [next_due_date, setNextDueDate] = useState('');

  
  let [errorMsg, setErrorMsg] = useState(null);
  let [saving, setSaving] = useState(false);
  
  
  let { putApi,getApi,postApi,user,ShowModalFunc } = useAuth();
 
  const { onClose, selectedValue, open, signatureM,reload } = props;
  let [due_day, setDueDay] = useState('');


  useEffect(() => {
    if(signatureM){
      setSignatureId(signatureM.id);
      setCancellationDate(dayjs(new Date()));
      setSuspensionDate(dayjs(new Date()));
      setNextDueDate(dayjs(new Date()));
      setStatusNotes(signatureM.status.status_notes);
      setDueDay(new Date().getDay())

      if(signatureM.status.id!=1){
        setAction(3);
      }

    }


  },[signatureM]);



  const handleClose = () => {
    onClose()
    reload();
    ShowModalFunc(null)
  };

  const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.isNativeApp?"90%":700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'scroll',
    overflowY: 'scroll',
    height:"80%",
    p: 4,
  };

  const fontSizeTexts=window.isNativeApp?10:13;

  
const HandleSubmit = async () => {
  setErrorMsg(null)
  setSaving(true)
  if(!checkValues()){
    setSaving(false)
    return false;
  }

  let submitPost={
    cancellation_date:cancellation_date,
    suspension_date:suspension_date,
    cancel_pending_invoices:cancel_pending_invoices,
    status_notes:statusNotes,
    action:parseInt(action),
    next_due_date:next_due_date,
    due_day:due_day
  };

  let response = await postApi("/invoice/signature/status/"+signatureId,submitPost);

  response_run(response);

}


const checkValues= ()=>{
  if(!cancellation_date && action==2){
    alert("Você precisa definir a data do cancelamento");
  }else if(!suspension_date && action==1){
    alert("Você precisa definir a data da suspensão");
  }
  else{
    return true;
  }
}


const response_run=(response)=>{
  setSaving(false);
  if(response.success){
    alert("Situação alterada com sucesso")
    if(action==3){
      if(new Date(next_due_date).getDate()-20 >= new Date() && signatureM.installments_cycle.id==1){
        alert("As faturas são geradas 20 dias antes do vencimento");
      }else{
        alert("Nova fatura gerada com sucesso");
      }
     
    }
    handleClose();
  }else{
    if(response.cancellation_date){
      setErrorMsg(response.cancellation_date.toString())
    }else if(response.suspension_date){
      setErrorMsg(response.suspension_date.toString())
    }else if(response.due_day){
      setErrorMsg(response.due_day.toString())
    }else if(response.action){
      setErrorMsg(response.action.toString())
    }
    
    else if(response.error){
      setErrorMsg( response.error.toString())
    }else{
      setErrorMsg( "Ocorreu um erro, tente novamente.")
    }
    setSaving(false)
  }
}


 

  return (
    <Modal
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box sx={style}>

      <Typography variant="h6" component="inline">
          <center> Alterar Situação: <br></br>
          <small>{signatureM.id}</small>
          </center>
      </Typography>

      <Typography sx={{ fontSize: window.isNativeApp?12:14 }} mt={1} mb={2}>
          Detalhes da Assinatura
        </Typography>
      <Typography variant="caption"  color="text.secondary" gutterBottom mt={2}>
           Status Atual: {signatureM.status.name}
           <br/>
           Cliente: {signatureM.costumer.fullname} - {signatureM.costumer.tax_identifier}
      </Typography>
      
      <Grid container spacing={0}>

        <TableContainer component={Paper} >
       
          <Table  size="small" aria-label="a dense table">
            <TableBody>
            
            <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="referenceField"><strong>Ação</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                <FormLabel id="demo-error-radios">O que você deseja fazer?</FormLabel>
                  <RadioGroup 
                  row
                    aria-labelledby="demo-error-radios"
                    name="action"
                  >
                    <FormControlLabel value="cancel" control={<Radio />} checked={action==2}  onClick={e=> {setAction(2); setCancelPendingInvoices(true)} } label="Cancelar o contrato." />
                    <FormControlLabel value="suspend" control={<Radio />} checked={action==1} onClick={e=> {setAction(1); setCancelPendingInvoices(false)}} label="Suspender o contrato" />
                    <FormControlLabel value="reactive" control={<Radio />} checked={action==3} disabled={signatureM.status.id==1} onClick={e=> {signatureM.status.id!=1 && setAction(3); }} label="Reativar o contrato" />
                  </RadioGroup>
                </TableCell>
              </TableRow>

              {action==1 &&
              
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="due_dayField"><strong>Data da suspensão</strong> </label>
                </TableCell>

                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}}>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  id="suspension_date"
                    label="Data que o contrato será cancelado"
                    inputFormat="dd/MM/yyyy"
                    value={suspension_date?suspension_date:null}
                    onChange={setSuspensionDate}
                    renderInput={(params) => <TextField  fullWidth {...params} />}
                  />
                  
                </LocalizationProvider>
                <small>
                Data que o contrato será suspenso.</small>
                </TableCell>
                
              </TableRow>
              }
             
              {action==2 &&
              
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="due_dayField"><strong>Data do Cancelamento</strong> </label>
                </TableCell>

                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}}>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  id="cancellation_date"
                    label="Data que o contrato será cancelado"
                    inputFormat="dd/MM/yyyy"
                    value={cancellation_date?cancellation_date:null}
                    onChange={setCancellationDate}
                    
                    renderInput={(params) => <TextField  fullWidth {...params} />}
                  />
                  
                </LocalizationProvider>
                <small>
                Data que o contrato será cancelado.</small>
                </TableCell>
                
              </TableRow>
              }

              {action==3 &&
              
              <React.Fragment>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="due_dayField"><strong>Dia do vencimento</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                <TextField
                  fullWidth
                  type="number"
                  value={due_day}
                  onChange={e=> setDueDay(e.target.value)}
                  id="due_dayField"
                  label="Dia de vencimento"
                />

                <small>Dia do mês que as próximas fatura serão geradas</small>

                </TableCell>
              </TableRow>
              <p></p>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="due_dayField"><strong>Vencimento da próxima Fatura</strong> </label>
                </TableCell>

                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}}>
                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  id="next_due_date"
                    label="Data da próxima Fatura"
                    inputFormat="dd/MM/yyyy"
                    value={next_due_date?next_due_date:null}
                    onChange={setNextDueDate}
                    renderInput={(params) => <TextField  fullWidth {...params} />}
                  />
                  
                </LocalizationProvider>
                <small>O vencimento da próxima fatura.</small>
                </TableCell>
                
              </TableRow>
              </React.Fragment>
              }


              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="referenceField"><strong>Observações</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                <TextareaAutosize 
                  maxRows={5}
                  value={statusNotes}
                  onChange={e=> setStatusNotes(e.target.value)}
                  style={{width:'100%', minHeight:"50px", marginTop:10}}
                  aria-label="maximum height"
                  placeholder={"Escreva anotações sobre o novo status"}
                  defaultValue=""
                />
                <small>
                (Opcional) </small>
                </TableCell>
                
              </TableRow>

             {
              action==2 &&
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                > 
                <TableCell component="th" scope="row" sx={{fontSize:fontSizeTexts,fontFamily:FontFamily, textAlign:"center"}}>
                <label for="referenceField"><strong>Cancelar faturas pendentes</strong> </label>
                </TableCell>
                <TableCell align="left" sx={{fontSize:fontSizeTexts, fontFamily:FontFamily}} >
                <Switch defaultChecked={cancel_pending_invoices} value={cancel_pending_invoices} onChange={e=> setCancelPendingInvoices(!cancel_pending_invoices)} />
                
                </TableCell>
                
              </TableRow>
             }

            </TableBody>
          </Table>
          
        <Typography variant="subtitle2"  color="text.secondary" gutterBottom mt={2} justifyContent={"center"}>
        <center>
        {
          action==1 && signatureM.status.id!=2 &&
            <React.Fragment>
            O contrato será suspenso no dia {(
            new Date(suspension_date).toDateString()==new Date().toDateString()?
            "de Hoje"
            :
            new Date(suspension_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}))
            }
          <br></br>
          Nenhuma fatura será gerada antes da data da suspensão
          </React.Fragment>
        }
      
        {
          action==2 && signatureM.status.id!=3 &&
        "O contrato será cancelado no dia "+(
          new Date(cancellation_date).toDateString()==new Date().toDateString()?
          "de Hoje"
          :
          new Date(cancellation_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}))
        }
      
        {cancel_pending_invoices && [1,2].includes(action) && signatureM.installments_pending>0 &&
          <React.Fragment>
          <br></br>
          Todas as faturas pendentes serão canceladas
          </React.Fragment>
        }

        {
          action==3 &&
          "O contrato será reativado hoje"
        }
       
        {[3].includes(action) &&
          <React.Fragment>
          <br></br>
          Será gerado uma fatura com vencimento no dia { new Date(next_due_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}) }
          <br></br>
          As próximas faturas serão geradas sempre no dia {due_day} do mês.
          </React.Fragment>
        }

        {[1].includes(action) && signatureM.status.id==2 &&
          <React.Fragment>
          <br></br>
          A assinatura já está suspensa. <br></br> Isso apenas atualizará a Data da Suspensão e Observação sem efeito nas faturas.
          </React.Fragment>
        }

        {[2].includes(action) && signatureM.status.id==3 && signatureM.installments_pending==0 &&
          <React.Fragment>
          <br></br>
          A assinatura já está cancelada. <br></br> Isso apenas atualizará a data do cancelamento e observações sem efeito nas faturas.
          </React.Fragment>
        }


        </center>
        </Typography>
        </TableContainer>

         
      

      </Grid>
      <center>
      <Button sx={{marginTop:5}}
        variant="contained"
        size="medium"
        color={'boot_primary'} 
        disabled={saving}
        onClick={e=> HandleSubmit()} 
      >
        Salvar Alteração
      </Button>
      </center>

      <Grid>
      <Button 
        variant="contained"
        size="small"
        color={'secondary'}  disabled={saving}
        onClick={e=> handleClose()}
      >
        Fechar
      </Button>
      </Grid>
      <Grid container justifyContent="center" >
              <Grid item>
              <Typography color="#df4759" variant="h7" >{errorMsg}</Typography>
              {saving &&
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>}
              </Grid>
            </Grid> 
      </Box>
      </Modal>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default ChangeSituation;
